<template>
  <el-dialog append-to-body :title="title" :close-on-click-modal="false" :visible="dialogVisible" width="300px" @close="close">
    <div class="dialog-wrap my-p-t-10">
      <el-form ref="form" :rules="rules" :model="form" label-position="top" label-width="100px">
        <el-form-item label prop="code">
          <el-input ref="txt" v-model="form.code" placeholder="验证码" maxlength="6" />
        </el-form-item>
        <el-form-item label v-loading="loading">
          <div class="my-flex my-col-top" v-if="imageUrl">
            <img :src="imageUrl" alt="点击刷新" @click="refreshImage" />
            <el-link
              :underline="false"
              type="info"
              style="font-size: 12px;margin-left: 5px;"
              @click="refreshImage"
              class="my-m-l-10"
            >看不清?换一张</el-link>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <el-button @click="close">关闭</el-button>
      <el-button type="primary" @click="handleSave">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import API from '@/api/_common'
export default {
  name: '',
  props: {
    title: {
      type: String,
      default: '请输入图中验证码'
    },
    label: {
      type: String,
      default: '请输入图中验证码'
    }
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      imageKey: Math.random() * 100000000000000000,
      imageUrl: '',
      form: {
        code: ''
      },
      rules: {
        code: [{ required: true, message: '请填写图中验证码', trigger: ['blur', 'change'] }]
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    close() {
      console.log('close')
      this.dialogVisible = false
      this.$emit('close')
    },
    confirm() {
      console.log('confirm')
      // this.dialogVisible = false
      this.$emit('confirm', { key: this.imageKey, code: this.form.code })
    },
    handleOpen() {
      console.log('handleOpen')
      this.dialogVisible = true
      this.refreshImage()
    },
    refreshImage() {
      console.log('refreshImage', this.form)
      this.loading = true
      this.form.code = ''
      API.verifyCodeImage({ key: this.imageKey }).then(res => {
        console.log('refreshImage', res)
        this.imageUrl = res.data || res.message
        this.loading = false
        this.$nextTick(() => {
          this.$refs.form.clearValidate(['code'])
          this.$refs.txt.focus()
        })
      })
    },
    handleSave() {
      console.log('handleSave', this.form)
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.confirm()
        } else {
          this.$refs.txt.focus()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>